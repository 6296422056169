var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "sidebar-open";
},"3":function(container,depth0,helpers,partials,data) {
    return "content-full";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"viewerMain\">\n    <!-- Toolbar -->\n"
    + ((stack1 = container.invokePartial(require("./Toolbar.html"),depth0,{"name":"Toolbar","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <!-- Viewport Content -->\n    <div class=\"content\">\n        <div class=\"sidebarMenu "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"seriesPanelOpen") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":32},"end":{"line":7,"column":74}}})) != null ? stack1 : "")
    + "\"></div>\n        <div class=\"mainContent "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"seriesPanelOpen") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":32},"end":{"line":8,"column":82}}})) != null ? stack1 : "")
    + "\">\n            <div id=\"layoutManagerTarget\">\n                <div class=\"viewportContainer\">\n                    <div class=\"removable\">\n                        <div class=\"imageViewerViewport\" unselectable=\"on\" tabindex=\"0\"></div>\n"
    + ((stack1 = container.invokePartial(require("./ImageControls.html"),depth0,{"name":"ImageControls","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./ViewportOrientationMarkers.html"),depth0,{"name":"ViewportOrientationMarkers","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <!-- Loading View which shows the percentage of the loaded files -->\n    <div class=\"loadingViewerMain\">\n        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"LoadingText") || (depth0 != null ? lookupProperty(depth0,"LoadingText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"LoadingText","hash":{},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":23}}}) : helper)))
    + " <span id=\"loadingPercentage\">0</span>%\n    </div>\n</div>";
},"usePartial":true,"useData":true});