var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "icon-loading": _vm.loading } },
    [
      _vm.error
        ? _c("div", [_vm._v("\n    " + _vm._s(_vm.error) + "\n  ")])
        : [
            _c(
              "div",
              { style: { display: "flex", justifyContent: "center" } },
              [
                _c("div", {
                  ref: "thumbnailElement",
                  style: { width: "256px", height: "256px" },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", [
              _c("h3", [
                _vm._v(_vm._s(_vm.t("dicomviewer", "DICOM Attributes"))),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.attributeSearchText,
                      expression: "attributeSearchText",
                    },
                  ],
                  style: { width: "100%", marginBottom: "10px" },
                  attrs: {
                    placeholder: _vm.t("dicomviewer", "Search for attributes…"),
                  },
                  domProps: { value: _vm.attributeSearchText },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.attributeSearchText = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "table",
                { style: { width: "100%", tableLayout: "fixed" } },
                [
                  _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.t("dicomviewer", "Attribute"))),
                    ]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.t("dicomviewer", "Value")))]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.attributes, function (attribute) {
                    return _c(
                      "tr",
                      {
                        key: attribute.tagName,
                        style: {
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                          fontSize: "smaller",
                        },
                      },
                      [
                        _c("td", { style: { padding: "5px 0 5px 0" } }, [
                          _c("p", [_vm._v(_vm._s(attribute.tagName))]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(attribute.tagValue))]),
                        ]),
                        _vm._v(" "),
                        _c("td", {
                          style: { padding: "5px 0 5px 0" },
                          domProps: { innerHTML: _vm._s(attribute.text) },
                        }),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _vm.attributes.length === 0 && !_vm.loading
              ? _c("div", { style: { textAlign: "center", padding: "20px" } }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.t("dicomviewer", "No DICOM attribute found")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }