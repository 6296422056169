var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"annotationDialogs\">\n    <dialog id=\"annotationDialog\"\n            class=\"annotationDialog noselect\">\n        <h5>"
    + alias4(((helper = (helper = lookupProperty(helpers,"EnterAnnotationText") || (depth0 != null ? lookupProperty(depth0,"EnterAnnotationText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EnterAnnotationText","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":35}}}) : helper)))
    + "</h5>\n        <div class=\"annotationTextInputOptions\">\n            <label for=\"annotationTextInput\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"NewLabelText") || (depth0 != null ? lookupProperty(depth0,"NewLabelText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NewLabelText","hash":{},"data":data,"loc":{"start":{"line":6,"column":45},"end":{"line":6,"column":61}}}) : helper)))
    + "</label>\n            <input name=\"annotationTextInput\"\n                   class=\"annotationTextInput\"\n                   type=\"text\"\n                   tabindex=\"-1\"\n                   autocomplete=\"off\"/>\n        </div>\n        <button type=\"button\" class=\"btn btn-primary annotationDialogConfirm\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"OKText") || (depth0 != null ? lookupProperty(depth0,"OKText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OKText","hash":{},"data":data,"loc":{"start":{"line":13,"column":78},"end":{"line":13,"column":88}}}) : helper)))
    + "</button>\n    </dialog>\n\n    <dialog id=\"relabelAnnotationDialog\"\n            class=\"annotationDialog noselect\">\n        <h5>"
    + alias4(((helper = (helper = lookupProperty(helpers,"EditAnnotationText") || (depth0 != null ? lookupProperty(depth0,"EditAnnotationText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EditAnnotationText","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":34}}}) : helper)))
    + "</h5>\n        <div class=\"annotationTextInputOptions\">\n            <label for=\"annotationTextInput\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"NewLabelText") || (depth0 != null ? lookupProperty(depth0,"NewLabelText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NewLabelText","hash":{},"data":data,"loc":{"start":{"line":20,"column":45},"end":{"line":20,"column":61}}}) : helper)))
    + "</label>\n            <input name=\"annotationTextInput\"\n                   class=\"annotationTextInput\"\n                   type=\"text\"\n                   tabindex=\"-1\"\n                   autocomplete=\"off\"/>\n        </div>\n        <div>\n            <button type=\"button\" class=\"btn btn-primary relabelConfirm\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"OKText") || (depth0 != null ? lookupProperty(depth0,"OKText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"OKText","hash":{},"data":data,"loc":{"start":{"line":28,"column":73},"end":{"line":28,"column":83}}}) : helper)))
    + "</button>\n            <button type=\"button\" class=\"btn btn-primary relabelRemove\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"RemoveMarkerText") || (depth0 != null ? lookupProperty(depth0,"RemoveMarkerText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RemoveMarkerText","hash":{},"data":data,"loc":{"start":{"line":29,"column":72},"end":{"line":29,"column":92}}}) : helper)))
    + "</button>\n        </div>\n    </dialog>\n</div>";
},"useData":true});