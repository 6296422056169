var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"imageControlButton imageControlUp\">\n        <i class=\"fa fa-chevron-up\"></i>\n    </div>\n    <div class=\"imageControls\">\n        <div class=\"scrollbar\">\n            <input class=\"imageSlider\" type=\"range\" min=\"1\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"imageIndex") : depth0), depth0))
    + "\" max=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"numImages") : depth0), depth0))
    + "\"/>\n        </div>\n    </div>\n    <div class=\"imageControlButton imageControlDown\">\n        <i class=\"fa fa-chevron-down\"></i>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"imageControls\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"gt")||(depth0 && lookupProperty(depth0,"gt"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"numImages") : depth0),1,{"name":"gt","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":26}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});