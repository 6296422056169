var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":15,"column":17},"end":{"line":15,"column":23}}}) : helper)))
    + "\" tabindex=\"1\" class=\"toolbarSectionButton "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"toolActive") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":66},"end":{"line":15,"column":97}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"classes") || (depth0 != null ? lookupProperty(depth0,"classes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":15,"column":98},"end":{"line":15,"column":109}}}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":15,"column":118},"end":{"line":15,"column":127}}}) : helper)))
    + "\">\n            <div class=\"svgContainer\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"svgClasses") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"buttonLabel\">\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":24,"column":22},"end":{"line":24,"column":31}}}) : helper)))
    + "</span>\n            </div>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"svgClasses") || (depth0 != null ? lookupProperty(depth0,"svgClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"svgClasses","hash":{},"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":42}}}) : helper)))
    + "\"></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"iconClasses") || (depth0 != null ? lookupProperty(depth0,"iconClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"iconClasses","hash":{},"data":data,"loc":{"start":{"line":20,"column":26},"end":{"line":20,"column":41}}}) : helper)))
    + "\"></i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"toolbar\" class=\"toolbar\">\n    <div class=\"toggleSeriesPanel\">\n        <div id=\"toggleSeriesPanelButton\" tabindex=\"1\" class=\"toolbarSectionButton roundedButton toggleSeriesPanelButton "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"seriesPanelOpen") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":121},"end":{"line":3,"column":157}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"classes") || (depth0 != null ? lookupProperty(depth0,"classes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":158},"end":{"line":3,"column":169}}}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ToggleSeriesPanelText") || (depth0 != null ? lookupProperty(depth0,"ToggleSeriesPanelText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ToggleSeriesPanelText","hash":{},"data":data,"loc":{"start":{"line":3,"column":178},"end":{"line":3,"column":203}}}) : helper)))
    + "\">\n            <div class=\"svgContainer\">\n                <div class=\"svgContent series-panel\"></div>\n            </div>\n            <div class=\"buttonLabel\">\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"SeriesText") || (depth0 != null ? lookupProperty(depth0,"SeriesText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SeriesText","hash":{},"data":data,"loc":{"start":{"line":8,"column":22},"end":{"line":8,"column":36}}}) : helper)))
    + "</span>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"toolbarSectionTools\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(lookupProperty(helpers,"toolbarButtons")||(depth0 && lookupProperty(depth0,"toolbarButtons"))||alias2).call(alias1,{"name":"toolbarButtons","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":32}}}),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":27,"column":17}}})) != null ? stack1 : "")
    + "\n        <div class=\"moreTools js-more-tools\">\n            <div class=\"toolbarSectionButton notTool\">\n                <div class=\"svgContainer\">\n                    <i class=\"fa fa-chevron-down\"></i>\n                </div>\n                <div class=\"buttonLabel\">\n                    <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"MoreText") || (depth0 != null ? lookupProperty(depth0,"MoreText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MoreText","hash":{},"data":data,"loc":{"start":{"line":35,"column":26},"end":{"line":35,"column":38}}}) : helper)))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <a class=\"button-close js-close-viewer\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"CloseViewerText") || (depth0 != null ? lookupProperty(depth0,"CloseViewerText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CloseViewerText","hash":{},"data":data,"loc":{"start":{"line":41,"column":51},"end":{"line":41,"column":70}}}) : helper)))
    + "\"><i class=\"fa fa-times fa-lg\"></i></a>\n</div>";
},"useData":true});