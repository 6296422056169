var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "("
    + alias4(((helper = (helper = lookupProperty(helpers,"imageIndex") || (depth0 != null ? lookupProperty(depth0,"imageIndex") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageIndex","hash":{},"data":data,"loc":{"start":{"line":16,"column":82},"end":{"line":16,"column":96}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"numImages") || (depth0 != null ? lookupProperty(depth0,"numImages") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"numImages","hash":{},"data":data,"loc":{"start":{"line":16,"column":97},"end":{"line":16,"column":110}}}) : helper)))
    + ")";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"imageViewerViewportOverlay\">\n    <div class=\"topleft dicomTag\">\n        <div id=\"patientName\">"
    + alias3((lookupProperty(helpers,"formatPN")||(depth0 && lookupProperty(depth0,"formatPN"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"patientName") : depth0),{"name":"formatPN","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":59}}}))
    + "</div>\n        <div id=\"patientId\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"patientId") || (depth0 != null ? lookupProperty(depth0,"patientId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"patientId","hash":{},"data":data,"loc":{"start":{"line":4,"column":28},"end":{"line":4,"column":41}}}) : helper)))
    + "</div>\n    </div>\n\n    <div class=\"topright dicomTag\">\n        <div id=\"studyDescription\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"studyDescription") || (depth0 != null ? lookupProperty(depth0,"studyDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"studyDescription","hash":{},"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":55}}}) : helper)))
    + "</div>\n        <div id=\"studyDate\">"
    + alias3((lookupProperty(helpers,"formatDA")||(depth0 && lookupProperty(depth0,"formatDA"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"studyDate") : depth0),{"name":"formatDA","hash":{},"data":data,"loc":{"start":{"line":9,"column":28},"end":{"line":9,"column":55}}}))
    + " "
    + alias3((lookupProperty(helpers,"formatTM")||(depth0 && lookupProperty(depth0,"formatTM"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"studyTime") : depth0),{"name":"formatTM","hash":{},"data":data,"loc":{"start":{"line":9,"column":56},"end":{"line":9,"column":83}}}))
    + "</div>\n    </div>\n\n    <div class=\"load-progress-content\"></div>\n\n    <div class=\"bottomleft dicomTag\">\n        <div id=\"seriesNumber\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"SerText") || (depth0 != null ? lookupProperty(depth0,"SerText") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"SerText","hash":{},"data":data,"loc":{"start":{"line":15,"column":31},"end":{"line":15,"column":42}}}) : helper)))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"seriesNumber") || (depth0 != null ? lookupProperty(depth0,"seriesNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"seriesNumber","hash":{},"data":data,"loc":{"start":{"line":15,"column":43},"end":{"line":15,"column":59}}}) : helper)))
    + "</div>\n        <div id=\"instanceNumber\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"ImgText") || (depth0 != null ? lookupProperty(depth0,"ImgText") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"ImgText","hash":{},"data":data,"loc":{"start":{"line":16,"column":33},"end":{"line":16,"column":44}}}) : helper)))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"instanceNumber") || (depth0 != null ? lookupProperty(depth0,"instanceNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"instanceNumber","hash":{},"data":data,"loc":{"start":{"line":16,"column":45},"end":{"line":16,"column":63}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"numImages") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":64},"end":{"line":16,"column":118}}})) != null ? stack1 : "")
    + "</div>\n        <div id=\"dimensions\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"dimensions") || (depth0 != null ? lookupProperty(depth0,"dimensions") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"dimensions","hash":{},"data":data,"loc":{"start":{"line":17,"column":29},"end":{"line":17,"column":43}}}) : helper)))
    + "</div>\n        <div id=\"seriesDescription\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"seriesDescription") || (depth0 != null ? lookupProperty(depth0,"seriesDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"seriesDescription","hash":{},"data":data,"loc":{"start":{"line":18,"column":36},"end":{"line":18,"column":57}}}) : helper)))
    + "</div>\n    </div>\n\n    <div class=\"bottomright dicomTag\">\n        <div id=\"zoomLevel\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"zoomLevel") || (depth0 != null ? lookupProperty(depth0,"zoomLevel") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"zoomLevel","hash":{},"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":22,"column":41}}}) : helper)))
    + "</div>\n        <div id=\"compression\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"compression") || (depth0 != null ? lookupProperty(depth0,"compression") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"compression","hash":{},"data":data,"loc":{"start":{"line":23,"column":30},"end":{"line":23,"column":45}}}) : helper)))
    + "</div>\n        <div id=\"windowLevel\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"windowLevel") || (depth0 != null ? lookupProperty(depth0,"windowLevel") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"windowLevel","hash":{},"data":data,"loc":{"start":{"line":24,"column":30},"end":{"line":24,"column":45}}}) : helper)))
    + "</div>\n    </div>\n</div>";
},"useData":true});