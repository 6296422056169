const BASE_URL = `${OC.getProtocol()}://${OC.getHost()}`;

/**
 * Generate full url with protocol and hostname
 * @param url The given url must include sub path generated by OC.generateUrl
 * @returns {string}
 */
export default function (url) {
    return `${BASE_URL}${url}`;
}
