var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"thumbnailEntry\">\n    <div class=\"imageThumbnail\">\n        <div id=\"imageThumbnailCanvas"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stack") : depth0)) != null ? lookupProperty(stack1,"seriesNumber") : stack1), depth0))
    + "_"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stack") : depth0)) != null ? lookupProperty(stack1,"displaySetInstanceUid") : stack1), depth0))
    + "\" class=\"imageThumbnailCanvas\">\n            "
    + alias2(((helper = (helper = lookupProperty(helpers,"imageThumbnailCanvas") || (depth0 != null ? lookupProperty(depth0,"imageThumbnailCanvas") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"imageThumbnailCanvas","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":36}}}) : helper)))
    + "\n        </div>\n    </div>\n    <div class=\"seriesDetails\">\n        <div class=\"seriesDescription\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stack") : depth0)) != null ? lookupProperty(stack1,"seriesDescription") : stack1), depth0))
    + "</div>\n        <div class=\"seriesInformation\">\n            <div class=\"item item-series clearfix\">\n                <div class=\"icon\">S:</div>\n                <div class=\"value\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stack") : depth0)) != null ? lookupProperty(stack1,"seriesNumber") : stack1), depth0))
    + "</div>\n            </div>\n            <div class=\"item item-frames clearfix\">\n                <div class=\"icon\"><div></div></div>\n                <div class=\"value\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stack") : depth0)) != null ? lookupProperty(stack1,"numImageFrames") : stack1), depth0))
    + "</div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});