var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"studies") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":5,"column":12},"end":{"line":9,"column":21}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(lookupProperty(helpers,"studyThumbnails")||(depth0 && lookupProperty(depth0,"studyThumbnails"))||container.hooks.helperMissing).call(alias1,blockParams[0][0],{"name":"studyThumbnails","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":6,"column":24},"end":{"line":6,"column":47}}}),{"name":"each","hash":{},"fn":container.program(3, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":6,"column":16},"end":{"line":8,"column":25}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./ImageThumbnail.html"),depth0,{"name":"ImageThumbnail","hash":{"isActiveStudy":true},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"accordion\" id=\"studyBrowserAccordion\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"studies") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":12,"column":12},"end":{"line":45,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"card\">\n                    <div class=\"card-header\" id=\"heading"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"studyIndex") : stack1), depth0))
    + "\">\n                        <div data-toggle=\"collapse\" data-target=\"#collapse"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"studyIndex") : stack1), depth0))
    + "\"\n                             aria-expanded=\"true\" aria-controls=\"collapse"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"studyIndex") : stack1), depth0))
    + "\">\n                            <div class=\"study-item-box\">\n                                <div class=\"study-modality\">\n                                    <div class=\"study-modality-text\">\n                                        "
    + alias2((lookupProperty(helpers,"modalitiesList")||(depth0 && lookupProperty(depth0,"modalitiesList"))||alias4).call(alias3,blockParams[0][0],{"name":"modalitiesList","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":20,"column":40},"end":{"line":20,"column":64}}}))
    + "\n                                    </div>\n                                </div>\n                                <div class=\"study-text\">\n                                    <div class=\"study-date\">"
    + alias2((lookupProperty(helpers,"formatDA")||(depth0 && lookupProperty(depth0,"formatDA"))||alias4).call(alias3,((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"studyDate") : stack1),{"name":"formatDA","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":24,"column":60},"end":{"line":24,"column":88}}}))
    + "</div>\n                                    <div class=\"study-description\">"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"studyDescription") : stack1), depth0))
    + "</div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n                    <div id=\"collapse"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"studyIndex") : stack1), depth0))
    + "\" class=\"collapse show\" aria-labelledby=\"heading"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"studyIndex") : stack1), depth0))
    + "\">\n                        <div class=\"card-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams),"inverse":container.program(10, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":33,"column":28},"end":{"line":41,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </div>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(lookupProperty(helpers,"studyThumbnails")||(depth0 && lookupProperty(depth0,"studyThumbnails"))||container.hooks.helperMissing).call(alias1,blockParams[1][0],{"name":"studyThumbnails","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":34,"column":40},"end":{"line":34,"column":63}}}),{"name":"each","hash":{},"fn":container.program(8, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":34,"column":32},"end":{"line":36,"column":41}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./ImageThumbnail.html"),depth0,{"name":"ImageThumbnail","hash":{"isActiveStudy":true},"data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(lookupProperty(helpers,"studyThumbnails")||(depth0 && lookupProperty(depth0,"studyThumbnails"))||container.hooks.helperMissing).call(alias1,blockParams[1][0],{"name":"studyThumbnails","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":38,"column":40},"end":{"line":38,"column":63}}}),{"name":"each","hash":{},"fn":container.program(11, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":38,"column":32},"end":{"line":40,"column":41}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./ImageThumbnail.html"),depth0,{"name":"ImageThumbnail","data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"studyBrowser\" class=\"studyBrowser\">\n    <div class=\"scrollableStudyThumbnails\">\n        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"studies") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"studies") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),1,{"name":"eq","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":4,"column":14},"end":{"line":4,"column":35}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.program(5, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":4,"column":8},"end":{"line":47,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"usePartial":true,"useData":true,"useBlockParams":true});