var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade captureImageDialog\" tabindex=\"-1\" role=\"dialog\">\n    <div class=\"modal-dialog modal-lg\" role=\"document\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h3 class=\"modal-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"CaptureImageTitleText") || (depth0 != null ? lookupProperty(depth0,"CaptureImageTitleText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CaptureImageTitleText","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":65}}}) : helper)))
    + "</h3>\n                <button type=\"button\" class=\"close btn-close\" data-dismiss=\"modal\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"CloseText") || (depth0 != null ? lookupProperty(depth0,"CloseText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CloseText","hash":{},"data":data,"loc":{"start":{"line":6,"column":95},"end":{"line":6,"column":108}}}) : helper)))
    + "\"><span aria-hidden=\"true\">&times;</span></button>\n            </div>\n            <div class=\"modal-body\">\n                <div class=\"full-width\">\n                    <h3 class=\"modal-sub-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"CaptureImageDescText") || (depth0 != null ? lookupProperty(depth0,"CaptureImageDescText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CaptureImageDescText","hash":{},"data":data,"loc":{"start":{"line":10,"column":48},"end":{"line":10,"column":72}}}) : helper)))
    + "</h3>\n                </div>\n                <div class=\"form-content card-round\">\n                    <div class=\"form-column\">\n                        <table class=\"capture-settings\">\n                            <tr>\n                                <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"WidthText") || (depth0 != null ? lookupProperty(depth0,"WidthText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"WidthText","hash":{},"data":data,"loc":{"start":{"line":16,"column":36},"end":{"line":16,"column":49}}}) : helper)))
    + "</td>\n                                <td>\n                                    <input type=\"number\" id=\"viewport-preview-width\" class=\"form-control form-control-fixed js-preview-size\" data-size=\"width\" value=\"512\" min=\"1\" max=\"16384\">\n                                </td>\n                                <td rowspan=\"2\" class='aspect-ratio-cell'>\n                                    <button id=\"keepAspectRatio\" data-keep-ratio=\"true\">\n                                        <i class=\"fa fa-link\"></i>\n                                    </button>\n                                </td>\n                            </tr>\n                            <tr>\n                                <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"HeightText") || (depth0 != null ? lookupProperty(depth0,"HeightText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HeightText","hash":{},"data":data,"loc":{"start":{"line":27,"column":36},"end":{"line":27,"column":50}}}) : helper)))
    + "</td>\n                                <td>\n                                    <input type=\"number\" id=\"viewport-preview-height\" class=\"form-control form-control-fixed js-preview-size\" data-size=\"height\" value=\"512\" min=\"1\" max=\"16384\">\n                                </td>\n                            </tr>\n                        </table>\n                    </div>\n\n                    <div class=\"form-column\">\n                        <table class=\"capture-settings\">\n                            <tr>\n                                <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"FileNameText") || (depth0 != null ? lookupProperty(depth0,"FileNameText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"FileNameText","hash":{},"data":data,"loc":{"start":{"line":38,"column":36},"end":{"line":38,"column":52}}}) : helper)))
    + "</td>\n                                <td>\n                                    <input type=\"text\" id=\"viewport-preview-name\" class=\"form-control\" value=\"image\">\n                                </td>\n                            </tr>\n                            <tr>\n                                <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"FileTypeText") || (depth0 != null ? lookupProperty(depth0,"FileTypeText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"FileTypeText","hash":{},"data":data,"loc":{"start":{"line":44,"column":36},"end":{"line":44,"column":52}}}) : helper)))
    + "</td>\n                                <td>\n                                    <select id=\"viewport-image-type\" class=\"form-control\">\n                                        <option value=\"png\" selected=\"selected\">PNG</option>\n                                        <option value=\"jpeg\">JPEG</option>\n                                    </select>\n                                </td>\n                            </tr>\n                        </table>\n                    </div>\n\n                    <div class=\"form-column\">\n                        <table class=\"capture-settings\">\n                            <tr>\n                                <td colspan=\"2\">\n                                    <div class=\"full-width show-annotations-container\">\n                                        <input type=\"checkbox\" id=\"showAnnotations\" class=\"form-control-checkbox\" name=\"showAnnotations\" checked value=\"show\">\n                                        <label class=\"form-label-checkbox\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ShowAnnotationsText") || (depth0 != null ? lookupProperty(depth0,"ShowAnnotationsText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ShowAnnotationsText","hash":{},"data":data,"loc":{"start":{"line":61,"column":75},"end":{"line":61,"column":98}}}) : helper)))
    + "</label>\n                                    </div>\n                                </td>\n                            </tr>\n                            <tr>\n                                <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"ImageQualityText") || (depth0 != null ? lookupProperty(depth0,"ImageQualityText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ImageQualityText","hash":{},"data":data,"loc":{"start":{"line":66,"column":36},"end":{"line":66,"column":56}}}) : helper)))
    + "</td>\n                                <td>\n                                    <input type=\"number\" id=\"viewport-preview-quality\" class=\"form-control form-control-fixed\" data-size=\"height\" value=\"100\" min=\"1\" max=\"100\">\n                                </td>\n                            </tr>\n                        </table>\n                    </div>\n                </div>\n\n                <div class=\"viewport-element-hidden\"></div>\n                <div class=\"viewport-preview-wrap\">\n                    <div class=\"card-round\">\n                        <h4>"
    + alias4(((helper = (helper = lookupProperty(helpers,"ImagePreviewText") || (depth0 != null ? lookupProperty(depth0,"ImagePreviewText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ImagePreviewText","hash":{},"data":data,"loc":{"start":{"line":78,"column":28},"end":{"line":78,"column":48}}}) : helper)))
    + "</h4>\n                        <img class=\"viewport-preview\"/>\n                    </div>\n                </div>\n            </div>\n            <div class=\"modal-footer\">\n                <button type=\"button\" class=\"btn btn-primary btn-close\" data-dismiss=\"modal\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"CloseText") || (depth0 != null ? lookupProperty(depth0,"CloseText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CloseText","hash":{},"data":data,"loc":{"start":{"line":84,"column":93},"end":{"line":84,"column":106}}}) : helper)))
    + "</button>\n                <button type=\"button\" id=\"downloadImage\" class=\"btn btn-primary btn-image-download\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"DownloadText") || (depth0 != null ? lookupProperty(depth0,"DownloadText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DownloadText","hash":{},"data":data,"loc":{"start":{"line":85,"column":100},"end":{"line":85,"column":116}}}) : helper)))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});